.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.other-sections {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.onmouse:hover {
  cursor: pointer
}

.cursor,.follower {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none
}

.follower {
  background-color: #7c96b7;
  height: 64px;
  width: 64px;
  z-index: 9999;
  -webkit-border-radius: 50em;
  -moz-border-radius: 50em;
  -ms-border-radius: 50em;
  -o-border-radius: 50em;
  border-radius: 50em;
  -webkit-transition: -webkit-transform ease,opacity ease;
  -webkit-transition-delay: 0.5s,0.5s;
  -moz-transition: -moz-transform ease 0.5s,opacity ease 0.5s;
  -o-transition: -o-transform ease 0.5s,opacity ease 0.5s;
  transition: transform ease 0.5s,opacity ease 0.5s;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-filter: contrast(0.5);
  -moz-filter: contrast(0.5);
  filter: contrast(0.5);
  filter: blur(10px);
  opacity: 0.5
}

.follower.is-active {
  -webkit-transform: scale(3);
  -moz-transform: scale(3);
  -ms-transform: scale(3);
  -o-transform: scale(3);
  transform: scale(3)
}